import React, { useState, useEffect, useContext } from "react";
import { getThisWeek, getWeek } from "../utils/time";
import "../utils/css/App.css";
import CustomLoader from "./CustomLoader";
import { IProjectResults, ITimeEntry, IUser, IWeekData } from "../types/types";
import WeektableNavigation from "./weektable/WeektableNavigation";
import WeektableDataRows from "./weektable/WeektableDataRows";
import * as Constants from "../utils/constants";
import SpinnerOverlay from "./SpinnerOverlay";
import { ApiContext } from "../providers/ApiProvider";
const offcodeLogo = require("../images/favicon.ico");

interface IWeekTableInterface {
    token: string;
    user: IUser;
}

export interface IDropdownElement {
    label: string; //company name
    value: string; //project ID
    project_id: string;
    image: any;
    key: string;
    private: boolean;
    __isNew__?: boolean;
}

// Weektable component
const WeekTable = (props: IWeekTableInterface) => {
    const { fetchProjects, getUser, getTimeEntriesBetween } = useContext(ApiContext);

    const [field, setField] = useState<IWeekData["dates"]>();
    const [dayTotal, setDayTotal] = useState<{ [x: string]: number }>({});
    const [selectedDays, setSelectedDays] = useState<number[]>([]);
    const [dbHours, setDbHours] = useState<ITimeEntry[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [changingWeek, setChangingWeek] = useState<boolean>(false);
    const [weekTotal, setWeekTotal] = useState<IWeekData["weekTotal"]>();
    const [user, setUser] = useState<IUser | undefined>();
    const [visibleProjects, setVisibleProjects] = useState<string[] | undefined>();
    const [overflow, setOverflow] = useState<"auto" | "visible">("visible");
    const [projectsArr, setProjectsArr] = useState<string[]>([""]);
    const [dropdownArr, setDropdownArr] = useState<IDropdownElement[] | undefined>();
    const [projectsData, setProjectsData] = useState<IProjectResults[] | undefined>();
    const [loadingCells, setLoadingCells] = useState<{ [key: string]: boolean }>({});

    // Component mounts
    useEffect(() => {
        initialFetch();
    }, []);

    // Resize event
    useEffect(() => {
        setOverflow(window.innerWidth >= Constants.MOBILEWIDTH ? "visible" : "auto");
    }, [window.innerWidth]);

    const initialFetch = async () => {
        try {
            await fetchUser().then((activeProjects) => {
                _fetchProjects(activeProjects); //fetch project
                setVisibleProjects(activeProjects);
            });

            const date = new Date();
            const thisWeek = getThisWeek(date);
            if (selectedDays !== thisWeek) setSelectedDays(thisWeek);
            await fetchTimeEntries(thisWeek); //fetch time entries
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    //TODO: This is horrible call. This takse long time and should not be like that. Fix me PLS
    const _fetchProjects = async (activeProjects: string[] | undefined) => {
        try {
            const listOfProjects: any = [];
            const projects: IProjectResults[] = await fetchProjects(props.token);
            const newArr: IDropdownElement[] = [];

            if (projects.length > 0) {
                for (const project of projects) {
                    listOfProjects.push(`${project.company_name}-${project.project_name}_${project.id}`);

                    // Check if user has selected projects
                    if (activeProjects) {
                        // Check that list does not include items that are already selected to visibleProjects array
                        if (!activeProjects.some((val) => val === project.id)) {
                            newArr.push({
                                label: project.company_name,
                                value: project.project_name,
                                project_id: project.id!,
                                image: offcodeLogo,
                                key: project.company_name + project,
                                private: project.private,
                            });
                        }
                    }
                }
            }
            setDropdownArr(newArr);
            setProjectsData(projects);
            setProjectsArr(listOfProjects);
        } catch (error) {
            console.error(error);
        }
    };

    // Fetch user and return visible projects
    const fetchUser = async () => {
        try {
            let user: IUser;

            if (props.user === null) {
                user = await getUser(props.token);
            } else {
                user = props.user;
            }
            setUser(user);
            return user.app_settings.visible_projects;
        } catch (error) {
            console.error(error);
        }
    };

    // Update project list & time entries
    const update = async (week?: any[] | undefined) => {
        try {
            console.info("Update started");
            await fetchUser().then((activeProjects) => {
                _fetchProjects(activeProjects); //fetch project
                setVisibleProjects(activeProjects);
            });

            const use = week ? week : selectedDays;
            await fetchTimeEntries(use); //fetch time entries
        } catch (error) {
            console.error(error);
        } finally {
            console.info("FINALLY Update done");
        }
    };

    // Get time entries(selected week)
    const fetchTimeEntries = async (week: any[]) => {
        try {
            if (week === undefined || week.length === 0) return;
            const date = {
                from: week[1].valueOf(),
                to: week[8].valueOf(),
            };
            const weekData: IWeekData = await getTimeEntriesBetween(props.token, date);
            setWeekTotal(weekData.weekTotal);
            setDbHours(weekData.timeEntries);
            setField(weekData.dates);
            setDayTotal(weekData.sum);
        } catch (error) {
            console.error(error);
        }
    };

    const handleWeekChange = async (week?: any[]) => {
        setChangingWeek(true);

        if (week) {
            // setLoadingCells;
            await fetchTimeEntries(week); //fetch time entries
            setSelectedDays(week);
            // setLoadingCells({});
            setChangingWeek(false);
        }
    };

    // Get previous week days
    const handlePrev = () => {
        handleWeekChange(getWeek(selectedDays[0], "prev"));
    };

    // Get next week days
    const handleNext = () => {
        handleWeekChange(getWeek(selectedDays[0], "next"));
    };

    // Get current week days
    const handleGoToday = () => {
        handleWeekChange(getThisWeek(new Date()));
    };

    // Return WeekTable Object
    return (
        <>
            {isLoading || !user ? (
                <CustomLoader msg="Fetching data" />
            ) : (
                <div>
                    <WeektableNavigation
                        getThisWeek={getThisWeek}
                        handleGoToday={handleGoToday}
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                        handleWeekChange={handleWeekChange}
                        selectedDays={selectedDays}
                    />
                    <div style={{ position: "relative" }}>
                        <WeektableDataRows
                            dayTotal={dayTotal}
                            dbHours={dbHours}
                            jwtoken={props.token}
                            weekTotal={weekTotal}
                            field={field}
                            overflow={overflow}
                            selectedDays={selectedDays}
                            setVisibleProjects={setVisibleProjects}
                            user={user!}
                            visibleProjects={visibleProjects}
                            dropdownArr={dropdownArr!}
                            projectsArr={projectsArr}
                            projectsData={projectsData}
                            loadingCells={loadingCells}
                            setField={setField}
                            fetchTimeEntries={fetchTimeEntries}
                            setDropdownArr={setDropdownArr}
                            setProjectsData={setProjectsData}
                            setLoadingCells={setLoadingCells}
                            update={update}
                        />
                        {changingWeek && <SpinnerOverlay />} {/* Conditionally render the spinner overlay */}
                    </div>
                </div>
            )}
        </>
    );
};
export default WeekTable;

import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";

import * as microsoftTeams from "@microsoft/teams-js";
import { IUser } from "../types/types";
import * as Constants from "../utils/constants";
import { customTheme, mobileProviderStyle } from "./ThemeProvider";
import {
    mergeThemes,
    teamsDarkTheme,
    teamsTheme,
    TeamsThemeStylesProps,
    ThemeInput,
    ThemePrepared,
} from "@fluentui/react-northstar";
import { ApiContext } from "./ApiProvider";

interface IContextProviderProps {
    children: ReactNode;
}

interface IAppContext {
    authorized?: boolean;
    setAuthorized: (authorized: boolean) => void;
    expiresIn?: number | undefined;
    setExpiresIn: (expiresIn: number | undefined) => void;
    user?: IUser | undefined;
    setUser: (user: IUser) => void;
    context?: microsoftTeams.app.Context | undefined;
    setContext: (context: microsoftTeams.app.Context) => void;
    token?: string | undefined;
    setToken: (token: string | undefined) => void;
    theme: ThemeInput | undefined;
    providerStyle: any;
    versionNumber?: string | undefined;
    isAnyCellLoading?: boolean;
    setIsAnyCellLoading: (isAnyCellLoading: boolean) => void;
}

export const AppContext = createContext<IAppContext>({
    authorized: false,
    setAuthorized: () => {},
    expiresIn: undefined,
    setExpiresIn: () => {},
    user: undefined,
    setUser: () => {},
    context: undefined,
    setContext: () => {},
    token: undefined,
    setToken: () => {},
    theme: undefined,
    providerStyle: {},
    versionNumber: undefined,
    isAnyCellLoading: false,
    setIsAnyCellLoading: () => {},
});

export default function ContextProvider({ children }: IContextProviderProps) {
    const { getServerSideVersion, getUser } = useContext(ApiContext);
    const [themeString, setThemeString] = useState<string>("default");
    const [theme, setTheme] = useState<ThemeInput | undefined>();
    const [authorized, setAuthorized] = useState<boolean>(false);
    const [expiresIn, setExpiresIn] = useState<number | undefined>();
    const [user, setUser] = useState<IUser | undefined>();
    const [context, setContext] = useState<microsoftTeams.app.Context | undefined>();
    const [token, setToken] = useState<string | undefined>();
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth >= Constants.MOBILEWIDTH ? false : true);
    const [versionNumber, setVersionNumber] = useState<string | undefined>();
    const providerStyle = isMobile ? mobileProviderStyle : {};
    const [isAnyCellLoading, setIsAnyCellLoading] = useState<boolean>(false);

    useEffect(() => {
        microsoftTeams.app.registerOnThemeChangeHandler((theme) => {
            console.info("Theme Changed: ", theme);
            setThemeString(theme);
        });

        window.addEventListener("resize", updateMobileState);

        authenticate()
            .then(() => {
                getServerSideVersion().then((res) => {
                    console.log("Backend version: " + res.version);
                    console.log("Frontend version: " + process.env.REACT_APP_VERSION);
                    setVersionNumber(process.env.REACT_APP_VERSION + " / " + res.version);
                });
            })
            .catch((error) => {
                console.error("Error while authenticating: ", error);
                // setErrorMsg(
                // <Provider.Consumer
                //     render={(theme) => (
                //         <>
                //             <p> Authentication failed. </p>
                //             <p> Try to refresh the page. If the problem persists, contact Toni K.</p>
                //             <button
                //                 style={{
                //                     marginTop: "10px",
                //                     padding: "5px",
                //                     borderRadius: "5px",
                //                     backgroundColor: theme?.siteVariables?.colorScheme.brand.background,
                //                     color: theme?.siteVariables?.colorScheme.brand.foregroundHover1,
                //                 }}
                //                 onClick={() => {
                //                     window.location.reload();
                //                 }}
                //                 onMouseEnter={(e) => {
                //                     e.currentTarget.style.backgroundColor =
                //                         theme?.siteVariables?.colorScheme.brand.backgroundHover;
                //                     e.currentTarget.style.cursor = "pointer";
                //                 }}
                //                 onMouseLeave={(e) => {
                //                     e.currentTarget.style.backgroundColor =
                //                         theme?.siteVariables?.colorScheme.brand.background;
                //                     e.currentTarget.style.cursor = "default";
                //                 }}
                //             >
                //                 Reload
                //             </button>
                //         </>
                //     )}
                // />
                // );
            });

        return () => {
            window.removeEventListener("resize", updateMobileState);
        };
    }, []);

    useEffect(() => {
        if (context) {
            const appTheme = context?.app.theme;
            setThemeString(appTheme);
        }
    }, [context]);

    useEffect(() => {
        const _customTheme = customTheme; //custom theme props
        const _theme: ThemePrepared<TeamsThemeStylesProps> =
            themeString === "dark" || themeString === undefined ? teamsDarkTheme : teamsTheme; //teams theme props

        setTheme(mergeThemes(_theme, _customTheme)); //merge custom theme with teams theme
    }, [themeString]);

    /**
     * Updates the state of isMobile
     */
    const updateMobileState = () => {
        setIsMobile(window.innerWidth >= Constants.MOBILEWIDTH ? false : true);
    };

    async function checkAuth(token: string) {
        await getUser(token).then((user) => {
            if (user) {
                setUser(user);
            } else {
                console.warn("User not found, let's create one. TODO: Implement user creation");
            }
            return user;
        });
    }

    /**
     * Authenticates the user and sets the context and token in the state
     *
     * @returns {Promise<void>}
     */
    const authenticate = async (): Promise<void> => {
        microsoftTeams.app.notifySuccess();
        // Get the user context from Teams and set it in the state
        await microsoftTeams.app.getContext().then(async (context) => {
            setContext(context);

            // Get Auth token from AD
            const authTokenRequest: microsoftTeams.authentication.AuthTokenRequestParameters = {
                resources: [],
                silent: false,
            };

            const token = await microsoftTeams.authentication.getAuthToken(authTokenRequest);
            if (!token) {
                throw new Error("Token is missing");
            }
            setToken(token);
            await checkAuth(token);
            // microsoftTeams.app.notifySuccess();
        });

        window.addEventListener("onload", updateMobileState);
    };

    return (
        <AppContext.Provider
            value={{
                authorized,
                setAuthorized,
                expiresIn,
                setExpiresIn,
                user,
                setUser,
                context,
                setContext,
                token,
                setToken,
                theme,
                providerStyle,
                versionNumber,
                isAnyCellLoading,
                setIsAnyCellLoading,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}

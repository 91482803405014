export const uploadFileToOneDrive = async (token: string, foldername: string, filename: string, data: BodyInit) => {
    console.info(`Write file: ${filename} into: ${filename}`);
    return fetch(`https://graph.microsoft.com/v1.0/me/drive/root:/${foldername}/${filename}:/content`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            authorization: "bearer " + token,
        },
        body: data,
        mode: "cors",
        cache: "default",
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw `Error ${response.status}: ${response.statusText} : ${response.body}`;
            }
        })
        .then((drive) => {
            return drive;
        });
};

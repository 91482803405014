import { Flex, Tooltip, Text, ObjectShorthandValue, TooltipContentProps } from "@fluentui/react-northstar";
import React from "react";
import * as Constants from "../../utils/constants";

interface IWeekTableProjectNameCellProps {
    projectName: string;
    companyName: string;
    privColor: string;
    privText:
        | boolean
        | React.ReactChild
        | React.ReactNode[]
        | ObjectShorthandValue<TooltipContentProps>
        | null
        | undefined;
    projectId: string;
    isLocked: boolean;
}

export default function WeekTableProjectNameCell(props: IWeekTableProjectNameCellProps) {
    return (
        <Flex gap="gap.smaller" column>
            <Text key={props.companyName} content={props.companyName} />
            <Tooltip
                trigger={
                    <div>
                        <Text
                            color={props.privColor}
                            content={props.projectName}
                            disabled={props.isLocked && props.projectId !== Constants.DEFAULT_PROJECT_ID}
                            // truncated
                            // size="small"
                        />
                    </div>
                }
                content={props.privText}
            />
        </Flex>
    );
}

import React from "react";
import { Text } from "@fluentui/react-northstar";

interface IWeektableWeekTotalCellProps {
    projectID: string;
    weekTotal: { [x: string]: number };
}

export default function WeektableWeekTotalCell(props: IWeektableWeekTotalCellProps) {
    return (
        <Text
            // align="center"
            size="medium"
            weight="semibold"
            color="orange"
            content={props.weekTotal[props.projectID]}
        />
    );
}

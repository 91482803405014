import { Text, DAYS_IN_WEEK, ShorthandValue, TableRowProps } from "@fluentui/react-northstar";
import React from "react";
import AddProjectDialog from "../components/AddProjectDialog";
import TimeEntryInput from "../components/TimeEntryInput";
import { IDropdownElement } from "../components/WeekTable";
import { IUser } from "../types/types";
import {
    DAY_TOTAL_CELL_STYLES,
    GENERATE_SELECT_PROJECT_STYLES,
    HIDE_COLUMN_BUTTON_STYLES,
    PROJECT_COLUMN_STYLES,
    WEEK_EXPECTED_TOTAL,
    WEEK_TOTAL_HEADER_STYLES,
} from "../components/weektable/utils";

// Generate headers for the week table
// dateArray: array of dates for the week: index 0 is the previous sunday, index 1-7 are the current week, index 8 is the next monday
export const generateHeaders = (dateArr: number[]): ShorthandValue<TableRowProps> => {
    const itemsArr: any[] = [];

    // Name of the project / company
    itemsArr.push({
        content: "PROJECT",
        key: "project",
        styles: PROJECT_COLUMN_STYLES,
    });

    // Dates for the week (mon-sun)
    for (let i = 1; i < DAYS_IN_WEEK + 1; i++) {
        const styles: React.CSSProperties = {
            minWidth: "100px",
            maxWidth: "100px",
        };
        const today = Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        if (today === dateArr[i]) {
            styles.color = "#C4314B";
            styles.textDecorationLine = "underline";
        }

        const date = new Date(dateArr[i]);

        itemsArr.push({
            // content: dateArr[i].format("ddd DD.MM.YY"),
            content: date.toLocaleDateString("en-GB", {
                weekday: "long",
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
            }),
            // key: dateArr[i].format("dd"),
            key: dateArr[i].toString(),
            styles: styles,
        });
    }
    // Total hours header
    itemsArr.push({
        content: "Week Total",
        key: "weekTotal",
        styles: WEEK_TOTAL_HEADER_STYLES,
    });

    // Hide button column header
    itemsArr.push({
        content: "",
        key: "hide",
        truncateContent: true,
        styles: HIDE_COLUMN_BUTTON_STYLES,
    });

    const header = {
        key: "header",
        items: itemsArr,
        header: true,
    };

    return header;
};

// eslint-disable-next-line no-unused-vars
const calculateDayTotal = (day: string, dayTotal: { [x: string]: number }): number => {
    let ret: number = 0;
    if (day in dayTotal) {
        ret = dayTotal[day];
    }
    return ret;
};

export const generateDayTotalCell = (
    selectedDays: any[],
    dayTotal: { [x: string]: number },
    weekTotal: { [s: string]: unknown } | ArrayLike<unknown>
) => {
    const row: any[] = [];
    row.push({
        key: `dayTotal_header`,
        content: (
            <div>
                <Text
                    // align="center"
                    // size="medium"
                    // weight="semibold"
                    // color="white"
                    content={"Day Total"}
                />
            </div>
        ),
        styles: PROJECT_COLUMN_STYLES,
    });

    for (let i = 0; i < DAYS_IN_WEEK; i++) {
        let color = "green";
        let total: number | string = calculateDayTotal(selectedDays[i + 1].valueOf(), dayTotal);
        if (total < DAYS_IN_WEEK) {
            color = "red";
        }
        total = total.toFixed(1);
        row.push({
            key: `dayTotal${i}`,
            content: (
                <div>
                    <Text align="center" size="medium" weight="semibold" color={color} content={total} />
                </div>
            ),
            styles: DAY_TOTAL_CELL_STYLES,
        });
    }

    // Week total cell
    let totalHours: string | number = 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [key, value] of Object.entries(weekTotal)) {
        totalHours += parseFloat(value as string);
    }
    let color = "green";
    if (totalHours < WEEK_EXPECTED_TOTAL) {
        color = "red";
    }
    totalHours = totalHours.toFixed(1);
    row.push({
        key: `dayTotal_weekTotal`,
        content: (
            <div>
                <Text align="center" size="medium" weight="semibold" color={color} content={totalHours} />
            </div>
        ),
        styles: DAY_TOTAL_CELL_STYLES,
    });
    return row;
};

// // Generate Trash Cell
// export const generateBudgetCell = (projectId: any, budget: { total: number; used: number }) => {
//     const tooltip = budget ? (
//         <div>
//             <div>Budget total: {budget.total} h</div>
//             <div>Budget used: {budget.used ? budget.used : 0} h</div>
//             <div>Budget left: {budget.total - budget.used} h</div>
//         </div>
//     ) : (
//         `No budget set to this project`
//     );
//     return {
//         key: `${projectId}-budget`,
//         content: (
//             <div>
//                 <Flex gap="gap.smaller">
//                     <Tooltip
//                         trigger={
//                             <Button
//                                 icon={
//                                     <InfoIcon
//                                         disabled={!budget}
//                                         style={{
//                                             color: "green",
//                                         }}
//                                     />
//                                 }
//                                 text
//                                 iconOnly
//                                 // onClick={}
//                             />
//                         }
//                         content={tooltip}
//                     />
//                 </Flex>
//             </div>
//         ),
//         styles: HIDE_COLUMN_BUTTON_STYLES,
//     };
// };

export const generateSelectProject = (
    dropdownArr: IDropdownElement[],
    user: IUser,
    jwtoken: string,
    handleSelectedProject: (project: IDropdownElement) => void,
    passProjectData: (data: IDropdownElement) => void
) => {
    return [
        {
            key: `Select_Project`,
            content: (
                <AddProjectDialog
                    dropdownArr={dropdownArr}
                    user={user}
                    key={"addProjectDialog"}
                    passChildData={passProjectData}
                    token={jwtoken}
                    handleSelectedProject={handleSelectedProject}
                />
            ),
            styles: GENERATE_SELECT_PROJECT_STYLES,
        },
    ];
};

//Currently not used
export const generateTimeEntryInput = (projectID: string, company: string, project: string, handleDuration: any) => {
    return {
        key: `${projectID}-timeEntryInput`,
        content: (
            <TimeEntryInput projectID={projectID} company={company} project={project} addDuration={handleDuration} />
        ),
        styles: {
            padding: 0,
        },
    };
};

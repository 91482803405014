import React, { useContext, useState } from "react";
import { Dropdown, Flex } from "@fluentui/react-northstar";
import ConfigureProjectDialog from "./ConfigureProjectDialog";
import { IProject, IUser } from "../types/types";
import { ApiContext, ICompany } from "../providers/ApiProvider";

interface IProjectSettings {
    user: IUser;
    jwtoken: string;
    companiesList: string[];
    companiesData: ICompany[];
    setMessage: (msg: string) => void;
    setSuccess: (success: boolean) => void;
}

export default function ProjectSettings(props: IProjectSettings) {
    const { getCompanyByName, getProjects } = useContext(ApiContext);

    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [projectsData, setProjectsData] = useState<IProject[]>([]);
    const [projectsList, setProjectsList] = useState<string[]>([]);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [selectedProjectName, setSelectedProjectName] = useState<string | null>();
    const [selectedCompany, setSelectedCompany] = useState<string | null>();

    const clearStates = async () => {
        setIsSelected(false);
        setSelectedProjectName(null);
        if (selectedCompany) {
            await getCompanyID(selectedCompany);
        }
    };

    const getCompanyID = async (companyName: string) => {
        try {
            const company = await getCompanyByName(props.jwtoken, companyName);
            _getProjects(company.id);
        } catch (error) {
            console.error(error);
        }
    };

    const _getProjects = (selectedCompanyID: string) => {
        getProjects(props.jwtoken, selectedCompanyID)
            .then((projects) => {
                const listOfProjects: string[] = [];
                let disabled = false;

                projects.forEach((project) => {
                    listOfProjects.push(project.project_name);
                });

                if (listOfProjects === undefined || listOfProjects.length == 0) {
                    console.info("No projects for this company available");
                    disabled = true;
                }
                setProjectsList(listOfProjects);
                setProjectsData(projects);
                setIsDisabled(disabled);
            })
            .catch((err) => console.error(err));
    };

    return (
        <>
            {/* <Text color={"red"} weight="bold" content={"<ADMINS ONLY> Change project settings"} /> */}
            <div style={{ maxWidth: "500px" }}>
                <Flex gap="gap.small">
                    <Dropdown
                        disabled={!props.user.is_admin && !props.user.is_project_manager}
                        items={props.companiesList}
                        placeholder="Select company"
                        checkable
                        clearable
                        fluid
                        getA11ySelectionMessage={{
                            onAdd: (item) => `${item} has been selected.`,
                        }}
                        onChange={(_e, data) => {
                            if (data.value) {
                                getCompanyID(data.value as string);
                                setSelectedCompany(data.value as string);
                            } else {
                                setSelectedCompany(null);
                                setIsDisabled(true);
                            }
                        }}
                    />
                </Flex>
                <p></p>
                <Flex gap="gap.small">
                    <Dropdown
                        disabled={isDisabled}
                        items={projectsList}
                        placeholder="Select project"
                        checkable
                        clearable
                        fluid
                        value={selectedProjectName}
                        getA11ySelectionMessage={{
                            onAdd: (item) => `${item} has been selected.`,
                        }}
                        onChange={(_e, data) => {
                            if (data.value) {
                                setIsSelected(true);
                                setSelectedProjectName(data.value as string);
                            } else {
                                setIsSelected(false);
                                setSelectedProjectName(null);
                            }
                        }}
                    />
                    <ConfigureProjectDialog
                        jwtoken={props.jwtoken}
                        user={props.user}
                        projectName={selectedProjectName}
                        projectId={projectsData.find((project) => project.project_name === selectedProjectName)?.id}
                        selected={isSelected}
                        setMessage={props.setMessage}
                        setSuccess={props.setSuccess}
                        clearStates={clearStates}
                    />
                </Flex>
            </div>
        </>
    );
}

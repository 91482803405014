export const REPORT_TYPES: string[] = ["Time entries", "Year report"];
export enum ReportType {
    TIME_ENTRIES = "Time entries",
    YEAR_REPORT = "Year report",
}

export interface User {
    id: string;
    app_settings: IAppSettings;
    full_name: string;
    email: string;
    oid: string;
    disabled: boolean;
    is_admin: boolean;
    is_project_manager: boolean;
    status_updated: number;
}

interface IAppSettings {
    type: object;
    visible_projects: string[]; //list of project ids
    properties: {
        theme: string;
    };
    default_project: {
        company_name: string;
        company_id: string;
        project_name: string;
        project_id: string;
        default_project_string: string;
    };
}

export interface IProject {
    active: {
        from: number | null;
        to: number | null;
    };
    admins: string[];
    company_id: string;
    entry_locked: boolean;
    id?: string;
    members: string[];
    owner_oid: string;
    private: boolean;
    project_name: string;
    tags: string[];
    budget: {
        used: number | null;
        total: number | null;
    };
}

export interface IUser {
    id?: string; // Database id
    app_settings: IAppSettings;
    full_name: string;
    email: string;
    oid: string;
    disabled: boolean;
    is_admin: boolean;
    is_project_manager: boolean;
    status_updated: number;
}

export interface ITimeEntry {
    id?: string; // Database id
    approved: boolean; // Is the time entry approved?
    created_by: string; // Created by user id
    created: number; // Unix timestamp
    modified: number; // Unix timestamp
    description: string; // Time entry description
    from: number; // Unix timestamp
    to: number; // Unix timestamp
    project_id: string; // Project id
}

export interface IProjectResults extends IProject {
    company_name: string;
}

export interface IWeekData {
    timeEntries: ITimeEntry[];
    weekTotal: any;
    sum: any;
    dates: any;
}

export interface ICompanies {
    company_name: string;
    id: string;
    locked: boolean;
}

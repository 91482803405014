import React, { useContext, useEffect, useState } from "react";
import "../utils/css/App.css";
import * as OneDrive from "../actions/oneDrive";
import moment from "moment";

import { Button, Dropdown, Checkbox, Datepicker, Provider, Popup, Loader, Text, Flex } from "@fluentui/react-northstar";
import { YearDropdown } from "../components/YearDropdown";
import CustomLoader from "../components/CustomLoader";
import { REPORT_TYPES, ReportType } from "../types/types";
import { AppContext } from "../providers/ContextProvider";
import { ApiContext, IFetchHoursBody, IProjectCompnayID } from "../providers/ApiProvider";
import { AlertContext } from "../providers/AlertProvider";
import ErrorMessage from "../components/ErrorMessage";

export default function ReportsTab() {
    const { theme, context, providerStyle, user, token, versionNumber } = useContext(AppContext);
    const { errorMsg, setErrorMsg } = useContext(AlertContext);
    const { getServerSideToken, fetchHours, fetchYearly, getCompanyByName, fetchHasHours, getUsers } =
        useContext(ApiContext);
    const [projectsList, setProjectsList] = useState<string[]>([]);
    const [userList, setUserList] = useState<string[]>([]);
    const [projectCompanyID, setProjectCompanyID] = useState<IProjectCompnayID[]>([]);
    const [usersData, setUsersData] = useState<any[]>([]);
    const [companiesList, setCompaniesList] = useState<string[] | undefined>();
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isCompanyDisabled, setIsCompanyDisabled] = useState<boolean>(true);
    const [startDate, setStartDate] = useState<number>();
    const [endDate, setEndDate] = useState<number>();
    const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
    const [selectedProjectsName, setSelectedProjectsName] = useState<string[]>([]);
    const [selectedUsersNames, setSelectedUsersNames] = useState<string[]>([]);
    const [selectedUsersIDs, setSelectedUsersIDs] = useState<string[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<string>();
    const [selectedCompanyName, setSelectedCompanyName] = useState<string>();
    const [companySelected, setCompanySelected] = useState<boolean>(false);
    const [popup, setPopup] = useState<any>(<Loader size="small" label="Saving..." />);
    const [serverSideToken, setServerSideToken] = useState<string>();
    const [selectedDateStart, setSelectedDateStart] = useState<Date>();
    const [selectedDateEnd, setSelectedDateEnd] = useState<Date>();
    const [getAllChecked, setGetAllChecked] = useState<boolean>(false);
    const [reportType, setReportType] = useState<string>();
    const [datePickerKey, setDatePickerKey] = useState<string>("datePicker");
    const [projectTypeSelected, setProjectTypeSelected] = useState<boolean>(false);
    const [year, setYear] = useState<number>(new Date().getFullYear());
    const [userSelected, setUserSelected] = useState<boolean>(false);

    useEffect(() => {
        if (token && user && context && context.user?.tenant?.id) {
            if (!user.is_admin) {
                setErrorMsg(
                    "You don't have permission to access this page. If you think this is a mistake, please contact Toni K."
                );
                return;
            }
            try {
                getServerSideToken(token, context.user?.tenant?.id).then(async (resp: string) => {
                    if (resp) {
                        setServerSideToken(resp);
                    }
                    _getUsers(token);
                    getCompanies(token);
                });
            } catch (error) {
                console.error(error);
                setErrorMsg("Error fetching data");
            }
        }
    }, [user]);

    const _getUsers = async (token: string) => {
        try {
            const listOfUserNames: string[] = [];
            const users = await getUsers(token);
            users.forEach((user) => {
                listOfUserNames.push(user.full_name);
            });

            if (listOfUserNames === undefined || listOfUserNames.length == 0) {
                console.warn("No Users found");
            }

            setUserList(listOfUserNames);
            setUsersData(users);
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    // eslint-disable-next-line no-unused-vars
    const createReportWorkBook = async (serversideToken: string, data: BodyInit, type: string | undefined) => {
        try {
            const date = new Date();
            const year = date.getFullYear();
            const timestamp = moment(date).format("YYYYMMDD-HHmmss");
            if (!user) {
                throw "User not found!";
            }
            const folderName = `Time Tracker/${user.full_name}/${year}`;
            let filename = "";
            let yearlyString = "";

            switch (type) {
                case ReportType.TIME_ENTRIES:
                    break;
                case ReportType.YEAR_REPORT:
                    yearlyString = "YEARLY_REPORT_";
                    break;
                default:
                    break;
            }

            if (selectedProjectsName.length > 0) {
                if (selectedUsersNames.length > 0) {
                    filename = `${selectedCompanyName}_${selectedProjectsName}_${selectedUsersNames}_${timestamp}.xlsx`;
                } else {
                    filename = `${selectedCompanyName}_${selectedProjectsName}_${timestamp}.xlsx`;
                }
            } else {
                if (selectedUsersNames.length > 0) {
                    filename = `${yearlyString}${selectedCompanyName}_all_projects_${selectedUsersNames}_${timestamp}.xlsx`;
                } else {
                    filename = `${yearlyString}${selectedCompanyName}_all_projects_and_users_${timestamp}.xlsx`;
                }
            }

            const response = await OneDrive.uploadFileToOneDrive(serversideToken, folderName, filename, data);
            const fullPath = response.webUrl;
            const simplePath = response.parentReference.path.split(":")[1];
            console.log(fullPath);
            console.log(filename);
            const popup = (
                <p>
                    <Text color="green" weight="bold">
                        Done!
                    </Text>
                    <br />
                    File: <b>{filename}</b> can be found from:
                    <br />
                    <b>{simplePath}</b>
                    <br />
                    <a target={"new"} style={{ color: "orange" }} href={fullPath}>
                        Link to the folder
                    </a>
                </p>
            );
            return popup;
        } catch (error) {
            console.error();
            return error;
        }
    };

    const getReport = async () => {
        try {
            const body: IFetchHoursBody = {
                company: selectedCompany,
                projects: selectedProjects,
                from: startDate?.valueOf(),
                to: endDate?.valueOf(),
                getAll: getAllChecked,
                users: selectedUsersIDs,
            };

            let resp: BodyInit | undefined;

            if (token && serverSideToken) {
                switch (reportType) {
                    case ReportType.TIME_ENTRIES:
                        {
                            resp = await fetchHours(token, body); //Creates time entries report in backend (returs stream)
                        }
                        break;

                    case ReportType.YEAR_REPORT:
                        {
                            /** Get firt and last date of the current year */
                            const firstDay = new Date(year, 0, 1);
                            const lastDay = new Date(year, 11, 31);

                            body.from = firstDay.valueOf();
                            body.to = lastDay.valueOf();

                            resp = await fetchYearly(token, body);
                        }
                        break;

                    default:
                        break;
                }
                if (resp) {
                    const respPopup = await createReportWorkBook(serverSideToken, resp, reportType);
                    setPopup(respPopup);
                } else {
                    throw "No response from server!";
                }
            } else {
                throw "Token missing!";
            }
        } catch (error: any) {
            console.error(error);
            setPopup(
                <Text color="red" weight="bold">
                    {JSON.stringify(error.error)}
                </Text>
            );
        }
    };

    const getCompanies = async (token: string) => {
        let companyDisabled = false;
        // setReady(false);

        const res = await fetchHasHours(token);

        /**Check that companies is not empty */
        if (res.companies === undefined || res.companies.length == 0) {
            console.warn("No companies found");
            companyDisabled = true;
            setCompanySelected(false);
        } else {
            companyDisabled = false;
        }
        // sort companies (alphabetically order take account lower and upper case)
        res.companies.sort((a: string, b: string) => a.localeCompare(b, "en", { sensitivity: "base" }));
        setCompaniesList(res.companies);
        setProjectCompanyID(res.projectCompanyID);
        setIsCompanyDisabled(companyDisabled);
        // setReady(true);
    };

    const getCompanyID = (companyName: string) => {
        if (!token) {
            setErrorMsg("Token missing!");
            return;
        }

        getCompanyByName(token, companyName)
            .then((company) => {
                // getProjects(response[0].id);
                const projects: string[] = [];

                projectCompanyID.forEach((project) => {
                    if (project.company_id === company.id) {
                        projects.push(project.project_name);
                    }
                });

                setSelectedCompany(company.id);
                setProjectsList(projects);
                setIsDisabled(false);
            })
            .catch((err) => console.error(err));
    };

    const handleCompanyChange = (d: any) => {
        if (d.value) {
            setSelectedCompanyName(d.value);
            if (d.value !== "==OFFCODE_USERS==" && d.value !== "== ALL ==") {
                getCompanyID(d.value);
            } else {
                setSelectedCompany(undefined); // Clear selected company
            }
            setCompanySelected(true);
        } else {
            clearFields();
        }
    };

    const handleProjectChange = (e: any, d: any = []) => {
        const projectIDs: string[] = [];

        for (const val in d.value) {
            projectCompanyID.forEach((item) => {
                if (item.project_name === d.value[val]) {
                    projectIDs.push(item.project_id);
                }
            });
        }

        setSelectedProjectsName(d.value);
        setSelectedProjects(projectIDs);
    };

    const handleUserChange = (e: any, d: any = []) => {
        const uIDArr: string[] = [];

        /** This is for multiselect */
        // for (const val in d.value) {
        //     for (const user in usersData) {
        //         if (usersData[user].full_name === d.value[val]) {
        //             uIDArr.push(usersData[user].id);
        //         }
        //     }
        // }

        if (d.value === null) d.value = undefined;

        for (const user in usersData) {
            if (usersData[user].full_name === d.value) {
                uIDArr.push(usersData[user].id);
            }
        }

        setSelectedUsersNames(d.value);
        setSelectedUsersIDs(uIDArr);
        setUserSelected(d.value ? true : false);
    };

    const handleReportTypeChange = (e: any, d: any = []) => {
        if (d.value === null) d.value = undefined;

        if (companiesList === undefined) {
            setErrorMsg("Companies list is empty!");
            return;
        }

        /** ==OFFCODE_USERS== are only user to get yearly report. Remove it otherwise*/
        if (companiesList[0] === "==OFFCODE_USERS==" || companiesList[0] === "== ALL ==") {
            const modifiedCompanyList = companiesList;
            modifiedCompanyList.shift();
            setCompaniesList(modifiedCompanyList);
        }

        setReportType(d.value);
        setProjectTypeSelected(d.value ? true : false);
        setUserSelected(true);

        const modifiedCompanyList = companiesList;

        if (d.value === ReportType.YEAR_REPORT) {
            modifiedCompanyList.unshift("==OFFCODE_USERS==");
        }

        if (d.value === ReportType.TIME_ENTRIES) {
            modifiedCompanyList.unshift("== ALL ==");
        }

        setSelectedDateStart(undefined);
        setSelectedDateEnd(undefined);
        setDatePickerKey(`datepickerKey${new Date()}`); //This is the way to trigger datepicker re-render
        setUserSelected(true);
        setCompaniesList(modifiedCompanyList);
    };

    const clearFields = () => {
        setPopup("");
        setSelectedCompany("");
        setSelectedCompanyName("");
        setSelectedProjects([]);
        setSelectedProjectsName([]);
        setSelectedUsersIDs([]);
        setSelectedUsersNames([]);
        setCompanySelected(false);
        setIsDisabled(true);
        setStartDate(undefined);
        setEndDate(undefined);
        setSelectedDateStart(undefined);
        setSelectedDateEnd(undefined);
        setUserSelected(false);
    };

    const handleYearChange = (year: number) => {
        console.log(`year: ${year}`);
        setYear(year);
    };
    return (
        <Provider theme={theme} style={providerStyle}>
            {!errorMsg ? (
                <div>
                    {companiesList ? (
                        <div
                            style={{
                                padding: 20,
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "start",
                                height: "100vh",
                                width: "100vw",
                                overflow: "hidden",
                                paddingLeft: 50,
                                paddingRight: 50,
                                paddingBottom: 50,
                                display: "flex",
                            }}
                        >
                            <Flex gap="gap.large">
                                <Flex.Item size="size.half" grow={1}>
                                    <div>
                                        <h3>Download project reports</h3>
                                        <p></p>
                                        <div style={{ maxWidth: "250px" }}>
                                            <Dropdown
                                                // disabled={projectTypeSelected}
                                                items={REPORT_TYPES}
                                                placeholder="Select report type"
                                                checkable
                                                clearable
                                                // multiple
                                                fluid
                                                value={reportType}
                                                getA11ySelectionMessage={{
                                                    onAdd: (item) => `${item} has been selected.`,
                                                }}
                                                onChange={handleReportTypeChange}
                                            />
                                            {projectTypeSelected ? (
                                                <>
                                                    <p></p>
                                                    <Dropdown
                                                        disabled={isCompanyDisabled}
                                                        items={companiesList}
                                                        placeholder="Select company"
                                                        // checkable
                                                        clearable
                                                        fluid
                                                        getA11ySelectionMessage={{
                                                            onAdd: (item) => `${item} has been selected.`,
                                                        }}
                                                        onChange={(e, d) => {
                                                            if (d) handleCompanyChange(d);
                                                        }}
                                                        value={selectedCompanyName}
                                                    />
                                                    <p></p>
                                                    {reportType === ReportType.YEAR_REPORT ? (
                                                        <>
                                                            <p></p>
                                                            <YearDropdown
                                                                projectChangeParentHandler={handleYearChange}
                                                            />
                                                            <p></p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Dropdown
                                                                disabled={isDisabled}
                                                                items={projectsList}
                                                                placeholder="Select project"
                                                                checkable
                                                                multiple
                                                                fluid
                                                                value={selectedProjectsName}
                                                                getA11ySelectionMessage={{
                                                                    onAdd: (item) => `${item} has been selected.`,
                                                                }}
                                                                onChange={handleProjectChange}
                                                            />
                                                            <p></p>
                                                            <Datepicker
                                                                inputPlaceholder="Select start date"
                                                                onDateChange={(e, data) => {
                                                                    if (data) {
                                                                        setStartDate(data.value?.valueOf());
                                                                        setSelectedDateStart(data.value);
                                                                    }
                                                                }}
                                                                selectedDate={selectedDateStart}
                                                                disabled={!companySelected}
                                                                input={{
                                                                    clearable: true,
                                                                }}
                                                                key={`start${datePickerKey}`}
                                                            />
                                                            <p></p>
                                                            <Datepicker
                                                                inputPlaceholder="Select end date"
                                                                onDateChange={(e, data) => {
                                                                    if (data) {
                                                                        setEndDate(
                                                                            data.value?.valueOf() +
                                                                                (23 * 60 + 59) * 60 * 1000
                                                                        );
                                                                        setSelectedDateEnd(data.value);
                                                                    }
                                                                }}
                                                                selectedDate={selectedDateEnd}
                                                                disabled={!companySelected}
                                                                input={{
                                                                    clearable: true,
                                                                }}
                                                                key={`end${datePickerKey}`}
                                                            />

                                                            {user && (user.is_admin || user.is_project_manager) ? (
                                                                <>
                                                                    <p></p>
                                                                    <Checkbox
                                                                        disabled={!companySelected}
                                                                        label="Check to collect everybody"
                                                                        toggle
                                                                        onChange={(e, d) => {
                                                                            if (d) {
                                                                                setGetAllChecked(d.checked);
                                                                                setSelectedUsersNames([]);
                                                                                setSelectedUsersIDs([]);
                                                                                setUserSelected(d.checked);
                                                                            }
                                                                        }}
                                                                    />
                                                                    <p></p>
                                                                    <Dropdown
                                                                        disabled={!companySelected || getAllChecked}
                                                                        items={userList}
                                                                        placeholder="Select user"
                                                                        checkable
                                                                        clearable
                                                                        // multiple
                                                                        fluid
                                                                        value={selectedUsersNames}
                                                                        getA11ySelectionMessage={{
                                                                            onAdd: (item) =>
                                                                                `${item} has been selected.`,
                                                                        }}
                                                                        onChange={handleUserChange}
                                                                    />
                                                                    <p></p>
                                                                </>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </>
                                            ) : null}
                                            <p></p>
                                        </div>
                                        <Popup
                                            position-align="above-start"
                                            content={popup}
                                            trigger={
                                                <Button
                                                    disabled={
                                                        !companySelected ||
                                                        !reportType ||
                                                        (reportType === ReportType.TIME_ENTRIES && !userSelected)
                                                    }
                                                    onClick={getReport}
                                                    content="Download"
                                                />
                                            }
                                            onOpenChange={(e, d) => {
                                                if (d && !d.open) {
                                                    // clearFields();
                                                    setPopup("");
                                                }
                                            }}
                                        />
                                    </div>
                                </Flex.Item>
                            </Flex>
                            <div
                                style={{
                                    position: "absolute",
                                    left: 5,
                                    bottom: 5,
                                    color: "gray",
                                }}
                            >
                                Version: {versionNumber}
                            </div>
                        </div>
                    ) : (
                        <CustomLoader msg="Fetching data..." />
                    )}
                </div>
            ) : (
                <ErrorMessage errorMsg={errorMsg} />
            )}
        </Provider>
    );
}

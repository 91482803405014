import * as React from "react";
import { Loader } from "@fluentui/react-northstar";

type ILoaderProps = {
    msg: string;
};

export const CustomLoader = (props: ILoaderProps) => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100vw",
                height: "100vh",
            }}
        >
            <Loader label={props.msg} />
        </div>
    );
};

export default CustomLoader;

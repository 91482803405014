import { Loader } from "@fluentui/react-northstar";
import React from "react";

const SpinnerOverlay = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                // backgroundColor: "rgba(0, 0, 0, 0.1)",
                backdropFilter: "blur(1px)",
                zIndex: 1000,
            }}
        >
            <Loader
                label="Loading..."
                styles={{
                    width: "100%",
                    minHeight: "40px",
                    display: "flex",
                }}
                size="largest"
            />
        </div>
    );
};

export default SpinnerOverlay;

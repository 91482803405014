import React, { useContext } from "react";

import "../utils/css/App.css";
import "../utils/css/Calendar.css";

import { Provider } from "@fluentui/react-northstar";

import WeekTable from "../components/WeekTable";
import CustomLoader from "../components/CustomLoader";
import { AppContext } from "../providers/ContextProvider";
import { AlertContext } from "../providers/AlertProvider";
import ErrorMessage from "../components/ErrorMessage";

export default function TimeEntryTab() {
    const { theme, providerStyle, user, token } = useContext(AppContext);
    const { errorMsg } = useContext(AlertContext);

    return (
        <Provider theme={theme} style={providerStyle}>
            {!errorMsg && token ? (
                <div
                    style={{
                        padding: 20,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "center",
                        height: "100vh",
                        width: "100vw",
                        overflowY: "scroll",
                        overflowX: "hidden",
                    }}
                >
                    {!user ? <CustomLoader msg="Fetching users" /> : <WeekTable token={token} user={user} />}
                </div>
            ) : (
                <ErrorMessage errorMsg={errorMsg} />
            )}
        </Provider>
    );
}

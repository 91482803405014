import React, { useState, useEffect, useRef } from "react";
import { components, GroupBase, IndicatorsContainerProps, IndicatorSeparatorProps } from "react-select";
import CreatableSelect from "react-select/creatable";
import { sortOn } from "../utils/helpers";
import { IDropdownElement } from "./WeekTable";
import { Dialog, ThemePrepared } from "@fluentui/react-northstar";
import { MAX_PROJECT_NAME_LENGTH } from "../utils/constants";

const PRIVATE = 0;
const PUBLIC = 1;

interface ICreatableProjectSearchInterface {
    projectList: IDropdownElement[];
    styles: ThemePrepared<any>;
    selectedProject: (project: IDropdownElement) => void;
    passSelectedProject: (project: string) => void;
    setPopUp: (value: boolean) => void;
}

interface IOptionGroup {
    label: string;
    options: IDropdownElement[];
}

export default function CreatableProjectSearch(props: ICreatableProjectSearchInterface) {
    const [options, setOptions] = useState<IOptionGroup[]>();
    const [value, setValue] = useState<IDropdownElement | null>();
    const [isPopupVisible, setIsPopupVisible] = useState<{ state: boolean; text: string | undefined }>({
        state: false,
        text: undefined,
    });
    const prevProjectListRef = useRef<IDropdownElement[]>(); // store previous project list

    useEffect(() => {
        prevProjectListRef.current = props.projectList;
    });

    const prevProjectList = prevProjectListRef.current; // get previous project list

    useEffect(() => {
        if (prevProjectList !== props.projectList) {
            setValue(null);

            const groups: IOptionGroup[] = [
                { label: "private", options: [] },
                { label: "public", options: [] },
            ];

            if (props.projectList) {
                props.projectList.forEach((project) => {
                    if (project.private) {
                        groups[PRIVATE].options.push(project);
                    } else {
                        groups[PUBLIC].options.push(project);
                    }
                });
            }
            sortOn(groups[PRIVATE].options, "label");
            sortOn(groups[PUBLIC].options, "label");
            setOptions(groups);
        }
    }, [props.projectList, prevProjectList]);

    const handleChange = async (newValue: IDropdownElement | null) => {
        if (newValue) {
            if (newValue.value.length > MAX_PROJECT_NAME_LENGTH) {
                console.error("Project name cannot be longer than 80 characters.");
                // Show error message popup to user
                setIsPopupVisible({
                    state: true,
                    text: `Project name cannot be longer than ${MAX_PROJECT_NAME_LENGTH} characters.`,
                });

                return;
            }

            if (newValue.value.length === 0) {
                console.error("Project name cannot be empty.");
                setIsPopupVisible({ state: true, text: "Project name cannot be empty." });
                return;
            }

            if (newValue.__isNew__ && newValue.__isNew__ === true) {
                console.info("Create new project!");
                props.setPopUp(true);
                props.passSelectedProject(newValue.value);
            } else {
                props.selectedProject(newValue);
            }
        }
    };

    const closePopup = () => {
        setIsPopupVisible({ state: false, text: undefined });
    };

    const customStyles = {
        overflow: "scroll",
        container: (provided: any) => ({
            ...provided,
            margin: -2,
        }),
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: props.styles.siteVariables.bodyBackground,
        }),
        option: (provided: any, { isFocused, data }: any) => ({
            ...provided,
            color: data.private
                ? props.styles.siteVariables.colors.green["200"]
                : props.styles.siteVariables.colors.orange["300"],
            backgroundColor: isFocused
                ? props.styles.siteVariables.colorScheme.brand.backgroundHover1
                : props.styles.siteVariables.bodyBackground,
        }),
        control: (provided: any) => ({
            ...provided,
            color: props.styles.siteVariables.colorScheme.brand.backgroundHover,
            backgroundColor: props.styles.siteVariables.bodyBackground,
            borderStyle: "none",
            borderRadius: 0,
            outline: "none",
            boxShadow: "none",
            flexDirection: "row-reverse",
            padding: "5px",
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: props.styles.siteVariables.colorScheme.brand.backgroundHover,
            backgroundColor: props.styles.siteVariables.bodyBackground,
            fontSize: "0.9rem",
        }),
        singleValue: (provided: any) => ({
            ...provided,
            backgroundColor: props.styles.siteVariables.bodyBackground,
        }),
        input: (provided: any) => ({
            ...provided,
            color: props.styles.siteVariables.colorScheme.brand.backgroundHover,
        }),
        groupHeading: (provided: any) => ({
            ...provided,
            fontSize: "0.8rem",
            color: props.styles.siteVariables.colorScheme.brand.backgroundHover,
            margin: 0,
        }),
    };

    return (
        <>
            <CreatableSelect<IDropdownElement>
                styles={customStyles}
                onChange={handleChange}
                onInputChange={(inputValue) => inputValue}
                options={options!}
                getOptionLabel={(option: IDropdownElement) => `${option.label}: ${option.value}`} // create label for options (company & project name)
                placeholder="Select project..."
                noOptionsMessage={() => "Add new project by typing its name"}
                value={value}
                components={{
                    IndicatorSeparator,
                    IndicatorsContainer,
                    // Group,
                }}
                formatCreateLabel={() => `Create new Project`}
            />

            {isPopupVisible && (
                <Dialog
                    open={isPopupVisible.state}
                    onCancel={closePopup}
                    onConfirm={closePopup}
                    header="Error"
                    content={<div>{isPopupVisible.text}</div>}
                    confirmButton="OK"
                />
            )}
        </>
    );
}

const indicatorStyle = {
    backgroundColor: "none",
};

const IndicatorSeparator = ({ innerProps }: IndicatorSeparatorProps<any>) => {
    return <span style={indicatorStyle} {...innerProps} />;
};

const IndicatorsContainer = (
    props: JSX.IntrinsicAttributes & IndicatorsContainerProps<IDropdownElement, false, GroupBase<IDropdownElement>>
) => {
    return (
        <div style={indicatorStyle}>
            <components.IndicatorsContainer {...props} />
        </div>
    );
};

export const WEEK_EXPECTED_TOTAL = 37.5;
export const DAYS_IN_WEEK = 7;

export const WEEK_TOTAL_HEADER_STYLES: React.CSSProperties = {
    minWidth: "100px",
    maxWidth: "100px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
};

export const HIDE_COLUMN_BUTTON_STYLES: React.CSSProperties = {
    minWidth: "50px",
    maxWidth: "50px",
};

export const PROJECT_COLUMN_STYLES: React.CSSProperties = {
    minWidth: "100px",
    maxWidth: "100px",
};

export const DAY_TOTAL_CELL_STYLES: React.CSSProperties = {
    minWidth: "100px",
    maxWidth: "100px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
};

export const GENERATE_SELECT_PROJECT_STYLES: React.CSSProperties = {
    // border: "2px solid red",
    display: "tableCell",
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
};

/**
 * Sort array of object based on prop
 * @param arr Array of object
 * @param prop Property name
 */
export const sortOn = (arr: any[], prop: string) => {
    arr.sort(function (a: { [x: string]: number }, b: { [x: string]: number }) {
        if (a[prop] < b[prop]) {
            return -1;
        } else if (a[prop] > b[prop]) {
            return 1;
        } else {
            return 0;
        }
    });
};

/**
 * Move array element to the first position
 * @param arr Array of object
 * @param value Value to search
 */
export const moveOnTop = (arr: any[], value: string) => {
    arr.sort((a: { key: string }, b: { key: string }) => {
        if (a.key.split("_")[1] == value) return -1;
        else if (b.key.split("_")[1] == value) return 1;
        else return 0;
    });
};

import React from "react";
import "../utils/css/App.css";

/**
 * This component is used to display the required
 * terms of use statement which can be found in a
 * link in the about tab.
 */
export default function TermsOfUseTab() {
    return (
        <div>
            <h1>Terms of Use</h1>
        </div>
    );
}

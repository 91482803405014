import React, { Component } from "react";
import { getTimeDuration, toAmPm } from "../utils/time";
import {
    // Input,
    Button,
} from "@fluentui/react-northstar";
import { PlayIcon, BanIcon } from "@fluentui/react-icons-northstar";

interface TimeEntryInputProps {
    projectID: string;
    project: string;
    company: string;
    addDuration: any;
    optionalArgument?: any;
    theme?: object;
}

export class TimeEntryInput extends Component<TimeEntryInputProps> {
    onPost: any;
    state: {
        startTime: any;
        startTimeAmPm: any;
        duration: any;
        text: string;
        timerId: any;
        changeTextSubmitTimeoutId: any;
        projectID: string;
        project: string;
        company: string;
        tags: string[];
        // theme: object;
    };

    constructor(props: TimeEntryInputProps) {
        super(props);
        const projectID = props.projectID ? props.projectID : "";
        const project = props.project ? props.project : "";
        const company = props.company ? props.company : "";
        this.state = {
            startTime: null,
            startTimeAmPm: null,
            duration: null,
            text: "",
            timerId: null,
            changeTextSubmitTimeoutId: null,
            projectID: projectID,
            project: project,
            company: company,
            tags: [],
            // theme: props.theme,
        };
        this.onPost = this.postData.bind(this);
    }

    componentDidMount = () => {};

    componentDidUpdate() {}

    componentWillUnmount = () => {
        this.stopTicking();
    };

    postData = () => {};

    stopTicking = () => {
        //clear previous timer if any
        // this.onPost();
        if (this.state.timerId) {
            this.props.addDuration(this.state.projectID, this.state.company, this.state.project, this.state.duration);
            clearInterval(this.state.timerId);
            this.setState({
                timerId: null,
                duration: null,
                text: "",
                startTime: null,
            });
        }
    };

    startTicking = () => {
        if (this.state.startTime) {
            const updateDuration = () => {
                const now = new Date();
                this.setState({
                    duration: getTimeDuration(this.state.startTime, now),
                });
            };

            updateDuration();
            //setup new timer to show duration

            const timerId = setInterval(() => {
                updateDuration();
            }, 1000);

            this.setState({
                timerId: timerId,
            });
        }
    };

    handleChangeText = (e: any) => {
        const text = e.target.value;
        this.setState({
            text: text,
        });
    };

    //Enter then start tracking
    handleKeyPress = (e: { nativeEvent: { keyCode: number } }) => {
        if (e.nativeEvent.keyCode === 13) {
            if (this.state.startTime) {
                return;
            }
        }
    };

    handleStart = () => {
        // e.preventDefault();
        if (!this.state.startTime) {
            // this.stopTicking();
            const startTime = new Date();
            const startTimeAmPm = toAmPm(startTime);
            const nextState = {
                startTime: startTime,
                startTimeAmPm: startTimeAmPm,
            };
            this.setState(nextState, () => {
                this.stopTicking();
                this.startTicking();
            });
        }
    };

    getA11ySelectionMessage = {
        onAdd: (item: any) => `${item.header} has been selected.`,
        onRemove: (item: any) => `${item.header} has been removed.`,
    };

    render() {
        let button;
        if (this.state.duration) {
            button = (
                <Button
                    disabled
                    icon={<BanIcon size="small" outline />}
                    text
                    iconOnly
                    // content="Stop"
                    loader="Override circuit"
                    primary
                    onClick={() => {
                        this.stopTicking();
                    }}
                />
            );
        } else {
            button = (
                <Button
                    disabled
                    icon={<PlayIcon size="small" outline />}
                    text
                    iconOnly
                    loader="Override circuit"
                    secondary
                    onClick={() => {
                        this.handleStart();
                    }}
                />
            );
        }

        return (
            <div className="time-entry-input-form">
                {/* <Input
                    // variables={this.state.theme}
                    clearable
                    placeholder="What are you doing?"
                    value={this.state.text}
                    onChange={this.handleChangeText}
                    onKeyPress={this.handleKeyPress}
                    id="text"
                    name="text"
                /> */}
                <span
                    style={{
                        // marginLeft: 20,
                        // minWidth: 80,
                        // display: "inline-block",
                        fontSize: "0,8rem",
                    }}
                >
                    {this.state.duration ? this.state.duration : "00:00:00"}
                </span>
                {button}
            </div>
        );
    }
}

export default TimeEntryInput;

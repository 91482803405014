import * as React from "react";
import { Alert, Text } from "@fluentui/react-northstar";

interface IAlert {
    msg: string;
    hideMessage: () => void;
    success: boolean;
}

export default function AlertExampleUrgent(props: IAlert) {
    return (
        <Alert
            success={props.success}
            danger={!props.success}
            content={
                <Text color={props.success ? "green" : "red"} weight="regular">
                    {props.msg}
                </Text>
            }
            dismissible
            dismissAction={{ "aria-label": "close" }}
            // variables={{ urgent: success }}
            onVisibleChange={() => props.hideMessage()}
        />
    );
}

import { ThemePrepared } from "@fluentui/react-northstar";
import React from "react";

interface IReloadButtonProps {
    theme: ThemePrepared<any>;
}

export default function ReloadButton(props: IReloadButtonProps) {
    return (
        <button
            style={{
                marginTop: "10px",
                padding: "5px",
                borderRadius: "5px",
                backgroundColor: props.theme?.siteVariables?.colorScheme.brand.background,
                color: props.theme?.siteVariables?.colorScheme.brand.foregroundHover1,
            }}
            onClick={() => {
                window.location.reload();
            }}
            onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = props.theme?.siteVariables?.colorScheme.brand.backgroundHover;
                e.currentTarget.style.cursor = "pointer";
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = props.theme?.siteVariables?.colorScheme.brand.background;
                e.currentTarget.style.cursor = "default";
            }}
        >
            Reload
        </button>
    );
}

/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { Button, Dialog, FormInput, SvgIconSizeValue, Tooltip } from "@fluentui/react-northstar";
import { AddIcon, NotesIcon } from "@fluentui/react-icons-northstar";
import * as Constants from "../utils/constants";

interface IDescriptionDialogTypes {
    hasDescription: string | null;
    id: string | undefined;
    token: string;
    cellKey: string;
    submitReady: (body: { description: string }, cellKey: string, id: string) => void;
}

export default function DescriptionDialog(props: IDescriptionDialogTypes) {
    const [popup, setPopup] = useState<boolean>(false);
    const [iconSize, setIconSize] = useState<SvgIconSizeValue>("small");
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        setIconSize(window.innerWidth >= Constants.MOBILEWIDTH ? "small" : "smaller");
    }, [window.innerWidth]);

    const handleSubmit = (description: string) => {
        setPopup(false);

        const body = {
            description: description,
        };
        props.submitReady(body, props.cellKey, props.id as string);
    };

    return (
        <Dialog
            styles={({ theme: { siteVariables } }) => ({
                color: siteVariables.colorScheme.brand.foreground,
            })}
            style={{ minWidth: "85%" }}
            open={popup}
            backdrop
            cancelButton={{
                content: "Cancel",
                styles: {
                    marginLeft: "0.574rem",
                },
            }}
            footer={{ styles: { direction: "rtl" } }}
            confirmButton={"Submit"}
            onCancel={() => setPopup(false)}
            onConfirm={() => {
                if (inputRef !== null && inputRef.current !== null) {
                    handleSubmit(inputRef.current.value);
                }
            }}
            trapFocus={{
                forceFocusInsideTrapOnComponentUpdate: true,
                forceFocusInsideTrapOnOutsideFocus: true,
            }}
            content={
                <FormInput
                    autoComplete="off"
                    maxLength={100}
                    defaultValue={props.hasDescription as string | undefined}
                    fluid
                    name="description"
                    ref={inputRef}
                />
            }
            onKeyPress={(e: { key: string }) => {
                if (e.key === "Enter") {
                    if (inputRef !== null && inputRef.current !== null) {
                        handleSubmit(inputRef.current.value);
                    }
                }
            }}
            header="Add description"
            trigger={
                <Button
                    icon={
                        <Tooltip
                            trigger={props.hasDescription ? <NotesIcon size={iconSize} /> : <AddIcon size={iconSize} />}
                            content={props.hasDescription}
                        />
                    }
                    onClick={() => setPopup(true)}
                    text
                    iconOnly
                />
            }
        />
    );
}

import React, { useContext, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import * as microsoftTeams from "@microsoft/teams-js";
import { IUser } from "../types/types";
import { ThemePrepared } from "@fluentui/react-northstar";
import { ApiContext } from "../providers/ApiProvider";

interface ICreatableCompanySearchInterface {
    user: IUser;
    selectedCompany: any;
    styles: ThemePrepared<any>;
    handleDisabled: (isDisabled: boolean) => void;
}

export default function CreatableCompanySearch(props: ICreatableCompanySearchInterface) {
    const { getCompanies, createCompany } = useContext(ApiContext);
    const [companies, setCompanies] = useState<any[]>([]);
    const [jwtoken, setJwtoken] = useState<string>("");
    const [styles] = useState<any>(props.styles);

    useEffect(() => {
        const authTokenRequest: microsoftTeams.authentication.AuthTokenRequest = {
            successCallback: (result: string) => {
                getCompanies(result)
                    .then((json) => {
                        const listOfCompanies: any = [];
                        json.forEach(
                            (companies: {
                                company_name: { toString: () => string };
                                id: { toString: () => string };
                            }) => {
                                const c = { label: "", value: "" };
                                c.label = companies.company_name.toString();
                                c.value = companies.id.toString();
                                listOfCompanies.push(c);
                            }
                        );
                        setCompanies(listOfCompanies);
                        setJwtoken(result);
                    })
                    .catch((err) => console.error(err));
            },
            failureCallback: function (error: string) {
                console.error("Failure: " + error);
            },
            resources: [],
        };
        microsoftTeams.authentication.getAuthToken(authTokenRequest);
    }, []);

    const createNewCompany = async (companyName: any): Promise<string> => {
        const body = {
            company_name: companyName,
            locked: false,
        };
        return createCompany(jwtoken, body)
            .then((json) => {
                const newCompanyObj = {
                    label: companyName,
                    value: json.id,
                };

                setCompanies([...companies, newCompanyObj]);
                return json.id;
            })
            .catch((err) => {
                console.error(err);
                return "";
            });
    };

    const getCompanyID = async (companyName: string): Promise<string> => {
        return getCompanies(jwtoken)
            .then((json) => {
                const ret = json.find(function (obj: { company_name: string }) {
                    return obj.company_name === companyName;
                });
                if (ret) {
                    return ret.id;
                } else {
                    return "";
                }
            })
            .catch((err) => {
                console.error(err);
                return "";
            });
    };

    const handleChange = async (newValue: any) => {
        if (newValue && newValue.value) {
            const company = { name: newValue.label, id: "" };

            // Check if company already exists
            if (
                companies.some((obj) => {
                    return obj.label === newValue.label;
                })
            ) {
                console.info("This company exits");
                company.id = await getCompanyID(newValue.label);
            } else {
                if (!props.user.is_admin) {
                    console.error("User is not admin, cannot create new company!");
                    return;
                }
                console.info("Create new company!");
                company.id = await createNewCompany(newValue.label);
            }
            if (company.id !== "") {
                props.selectedCompany(company);
            } else {
                console.error("Company ID was empty");
            }
        } else {
            props.handleDisabled(true);
        }
    };

    const handleInputChange = () => {
        //This triggers when input field closes/opens/clear etc. Do here something if needed.
    };

    const customStyles = {
        overflow: "scroll",
        container: (provided: any) => ({
            ...provided,
            width: 180,
        }),
        menu: (provided: any) => ({
            ...provided,
            // width: state.selectProps.width,
            backgroundColor: props.styles.siteVariables.bodyBackground,
        }),
        // eslint-disable-next-line no-unused-vars
        option: (provided: any, { isFocused }: any) => ({
            ...provided,
            // borderBottom: "1px dotted pink",
            color: isFocused ? "#F3F2F1" : null,
            backgroundColor: isFocused
                ? props.styles.siteVariables.colorScheme.brand.backgroundHover
                : props.styles.siteVariables.bodyBackground,
        }),
        control: (provided: any) => ({
            ...provided,
            color: props.styles.siteVariables.colorScheme.brand.backgroundHover,
            backgroundColor: props.styles.siteVariables.bodyBackground,
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: props.styles.siteVariables.colorScheme.brand.backgroundHover,
            backgroundColor: props.styles.siteVariables.bodyBackground,
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: props.styles.siteVariables.colorScheme.brand.backgroundHover,
            backgroundColor: styles.siteVariables.bodyBackground,
        }),
        input: (provided: any) => ({
            ...provided,
            color: props.styles.siteVariables.colorScheme.brand.backgroundHover,
        }),
    };

    return (
        <CreatableSelect
            isClearable
            maxMenuHeight={120}
            styles={customStyles}
            onChange={handleChange}
            onInputChange={handleInputChange}
            options={companies}
            placeholder="Select company"
            noOptionsMessage={() => "Add new company by typing its name"}
        />
    );
}

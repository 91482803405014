import React from "react";
import { Text } from "@fluentui/react-northstar";

interface IErrorMessageProps {
    errorMsg: string | React.ReactNode | undefined;
}

export default function ErrorMessage(props: IErrorMessageProps) {
    return (
        <div
            style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Text
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
                weight="bold"
                size="large"
                content={props.errorMsg}
            />
        </div>
    );
}

import React from "react";
import "../utils/css/App.css";
import * as microsoftTeams from "@microsoft/teams-js";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import PrivacyTab from "../tabs/PrivacyTab";
import TermsOfUseTab from "../tabs/TermsOfUseTab";
import SettingsTab from "../tabs/SettingsTab";
import ReportsTab from "../tabs/ReportsTab";
import TimeEntryTab from "../tabs/TimeEntryTab";
import ContextProvider from "../providers/ContextProvider";
import { ApiProvider } from "../providers/ApiProvider";
import AlertProvider from "../providers/AlertProvider";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
function App() {
    // Initialize the Microsoft Teams SDK
    microsoftTeams.app.initialize();

    // Display the app home page hosted in Teams
    return (
        <AlertProvider>
            <ApiProvider>
                <ContextProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/privacy" element={<PrivacyTab />} />
                            <Route path="/termsofuse" element={<TermsOfUseTab />} />
                            <Route path="/" element={<TimeEntryTab />} />
                            <Route path="/settings" element={<SettingsTab />} />
                            <Route path="/reports" element={<ReportsTab />} />
                        </Routes>
                    </BrowserRouter>
                </ContextProvider>
            </ApiProvider>
        </AlertProvider>
    );
}

export default App;

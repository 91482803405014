import React, { createContext, ReactNode, useState } from "react";

interface IContextProviderProps {
    children: ReactNode;
}

interface IAppContext {
    errorMsg?: string | React.ReactNode | undefined;
    setErrorMsg: (errorMsg: string | React.ReactNode | undefined) => void;
}

export const AlertContext = createContext<IAppContext>({
    errorMsg: undefined,
    setErrorMsg: () => {},
});

export default function AlertProvider({ children }: IContextProviderProps) {
    const [errorMsg, setErrorMsg] = useState<string | React.ReactNode | undefined>();

    return (
        <AlertContext.Provider
            value={{
                errorMsg,
                setErrorMsg,
            }}
        >
            {children}
        </AlertContext.Provider>
    );
}

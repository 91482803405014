import * as React from "react";
import {
    Table,
    Button,
    TableCell,
    Datepicker,
    gridNestedBehavior,
    Flex,
    ArrowLeftIcon,
    ArrowRightIcon,
} from "@fluentui/react-northstar";
import { useState } from "react";

interface IWeektableNavigationProps {
    selectedDays: number[];
    handleWeekChange: (week: Date[]) => void;
    handlePrev: () => void;
    handleNext: () => void;
    handleGoToday: () => void;
    getThisWeek: (date: Date) => Date[];
}

export default function WeektableNavigation(props: IWeektableNavigationProps) {
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(
        props.selectedDays[0] ? new Date(props.selectedDays[1]) : new Date()
    );

    React.useEffect(() => {
        setSelectedDate(props.selectedDays[1] ? new Date(props.selectedDays[1]) : new Date());
    }, [props.selectedDays]);

    return (
        <Table
            key="table"
            accessibility={gridNestedBehavior}
            styles={({ theme: { siteVariables } }) => ({
                color: siteVariables.colorScheme.brand.foreground,
            })}
        >
            <TableCell
                styles={{
                    marginTop: 0,
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
            >
                <p></p>
            </TableCell>
            <TableCell
                styles={{
                    marginTop: 0,
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
            >
                <Flex gap="gap.small">
                    <Button
                        iconOnly
                        size="medium"
                        key="previousButton"
                        icon={<ArrowLeftIcon />}
                        id="1"
                        onClick={props.handlePrev}
                    />
                    <Datepicker
                        buttonOnly
                        today={new Date()}
                        key="datePicker"
                        id="2"
                        selectedDate={selectedDate}
                        onDateChange={(e, data) => {
                            if (data) {
                                setSelectedDate(data.value);
                                props.handleWeekChange(props.getThisWeek(data.value));
                            }
                        }}
                    />
                    <Button
                        iconOnly
                        size="medium"
                        key="nextButton"
                        icon={<ArrowRightIcon />}
                        id="3"
                        onClick={props.handleNext}
                    />
                    <Button
                        // iconOnly
                        size="medium"
                        key="goToday"
                        content="TODAY"
                        // icon={<ArrowRightIcon />}
                        id="4"
                        onClick={props.handleGoToday}
                    />
                </Flex>
            </TableCell>
            <p></p>
        </Table>
    );
}
